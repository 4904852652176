.TwoFaQrCode {
  text-align: center;
}

.TwoFaQrCode-ImageBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: var(--space-xl);
}

.TwoFaQrCode-ImageBlock img {
  width: 120px;
  height: 120px;
  margin-bottom: var(--space-xs);
}

.TwoFaQrCode-InputsBlock {
  display: flex;
  margin-bottom: 4px;
}

.TwoFaQrCode-VerField {
  width: 240px;
  margin: 0 auto;
  margin-bottom: var(--space-l);
  text-align: left;
}

.TwoFaQrCode-InputsBlock > .TextField {
  width: 32px;
  height: 32px;
}

.TwoFaQrCode-InputsBlock > .TextField:not(:last-child) {
  margin-right: var(--space-xs);
}
.TwoFaQrCode-InputsBlock > .TextField:nth-child(3) {
  margin-right: var(--space-m);
}

.TwoFaQrCode-InputsBlock .TextField-ControlBox {
  min-width: none;
}
