.company-table table {
    display: table!important;
}

.company-table tr {
    display: table-row!important;
}

.company-table th, .company-table td {
    display: table-cell!important;
}

.company-table thead {
    display: table-header-group!important;
}

.company-table tbody {
    display: table-row-group!important;
}

.company-table span:not(.Switch):not(.Switch-Toggle):not(.Button-Content) {
    display: inline!important;
}

.company-table .field-name {
    color: #7e7e7e;
    margin-right: 5px
}

.CompanySelect-Select {
    margin-bottom: 32px;
}

.CompanySelect-Controls {
    display: flex;
    justify-content: flex-end;
}

.date-picker-field label, .date-picker-field input {
    color: #fff;
}

.internal-table th, .internal-table td {
    padding: 3px!important;
}
