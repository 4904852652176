.TwoFaResetPassword-SuccessfulActivation {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.TwoFaResetPassword-SuccessfulActivation > .Text {
  margin-bottom: var(--space-xl);
}
