.TwoFaResetPassword {
  display: block;
}

.TwoFaResetPassword-ProtectForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TwoFaResetPassword-Title {
  margin: 0;
  margin-bottom: var(--space-xs);
}

.TwoFaResetPassword-Description {
  margin-bottom: var(--space-m);
}

.TwoFaResetPassword-BackBtn {
  margin-bottom: var(--space-l);
}
