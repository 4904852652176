.ProductCard {
    position: relative;
    border: 2px solid transparent;
    border-radius: 16px;
    background: #080808;
    background-clip: padding-box;
    padding: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
    width: 190px;
    min-width: 190px;
    height: 210px;
    justify-content: space-between;
    flex-direction: column;
}

.ProductCard_with_link {
    cursor: pointer;
}

.ProductCard_Description {
    font-size: 11px;
}

.ProductCard::before {
    position: absolute;
    top: -2px; bottom: -2px;
    left: -2px; right: -2px;
    content: '';
    z-index: -1;
    border-radius: 16px;
}

.ProductCard-Title {
    margin: 0;
}

.ProductCard-Footer {
    /*display: flex;*/
    /*justify-content: flex-end;*/
}

.ProductCard-TrialButton {
    z-index: 1;
}

.ProductCard_ci::before {
    background: linear-gradient(65deg, #4B8193 -5%, rgba(105, 91, 195, 0.5) 50%, rgba(137, 50, 247, 0) 100%);
}

.ProductCard_sb::before {
    background: linear-gradient(65deg, #FFAE34 -5%, rgba(199, 115, 144, 0.5) 50%, rgba(137, 50, 247, 0) 100%);
}

.ProductCard_tds::before {
    background: linear-gradient(65deg, #3A3FA2 -5%, rgba(97, 57, 204, 0.5) 50%, rgba(137, 50, 247, 0) 100%);
}

.ProductCard_huntbox-module-atmosphere::before {
    background: linear-gradient(65deg, #3A3FA2 -5%, rgba(97, 57, 204, 0.5) 50%, rgba(137, 50, 247, 0) 100%);
}

.ProductCard_bp::before {
    background: linear-gradient(65deg, #B71D54 -5%, rgba(161, 39, 161, 0.5) 50%, rgba(137, 50, 247, 0) 100%);
}

.ProductCard_rt::before {
    background: linear-gradient(65deg, #A7C7AB -5%, rgba(152, 125, 209, 0.5) 50%, rgba(137, 50, 247, 0) 100%);
}

.ProductCard_tr::before {
    background: linear-gradient(65deg, #374E6A -5%, rgba(125, 209, 197, 0.5) 50%, rgba(137, 50, 247, 0) 100%);
}

.ProductCard_ai::before {
    background: linear-gradient(65deg, #B45B5B -1.03%, rgba(127, 68, 173, 0.5) 50%, rgba(75, 46, 255, 0) 100%)
}
