.CompanySelect-Overlay {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(3, 9, 25, 0.01);
}

.CompanySelect-Modal {
    position: absolute;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #030919;
    border-radius: 16px;
    padding: 16px;
    width: 560px;
    top: calc(20% - 100px);
    left: calc(50% - 280px);
    right: 50%;
}

.CompanySelect-Title {
    margin: 0;
    margin-bottom: 24px;
}

.CompanySelect-Label {
    display: block;
    margin-bottom: 8px;
}

.CompanySelect-Select {
    margin-bottom: 32px;
}

.CompanySelect-Controls {
    display: flex;
    justify-content: flex-end;
}

.Modal-Big {
    position: absolute;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #030919;
    border-radius: 16px;
    padding: 16px;
    width: 800px;
    top: calc(20% - 100px);
    left: calc(50% - 280px);
    right: 50%;
}