html,
body,
#root {
  margin: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}
body {
  background-color: #080808;
}

* {
  box-sizing: border-box;
  outline: none;
}

img {
  border: 0;
}

@import './fonts/index.css';

/* ереопредление цвета input ри автозаполнении */
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0 1000px rgba(24, 26, 27, .3) inset;
  transition: background-color 5000s ease-in-out 0s;
}
