.Card__title {
  margin-bottom: 12px;
}

.Card__Button {
  color: #ffffff;
}

.Card__bottom-controls {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
