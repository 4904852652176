.TwoFaVerification-Title {
  margin: 0;
  margin-bottom: var(--space-m);
}

.TwoFaVerification-Description {
  display: block;
  margin-bottom: var(--space-l);
}

.TwoFaVerification-RememberMeCheckBox {
  margin-top: 8px;
  margin-bottom: var(--space-xl);
}

.TwoFaVerification-RememberMeCheckBox label{
  color: var(--color-white-medium);
  font-size: 13px;
}
