.TwoFaCard {
  flex-direction: column;
  max-width: 400px;
  border-radius: var(--round-l);
  padding: var(--space-m);
  border: 1px solid var(--color-on-surface-white-12);
  background: none;
}

.TwoFaCard-Title {
  margin-bottom: var(--space-xs);
}

.TwoFaCard-Header {
  margin-bottom: var(--space-xs);
}

.TwoFaCard-Footer {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--space-m);
}
