.TwoFaModal {
  position: absolute;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.12)
    ),
    #030919;
  border-radius: 16px;
  padding: 16px;
  width: 560px;
  top: calc(50% - 200px);
  left: calc(50% - 280px);
  right: 50%;
}

.TwoFaModal-Overlay {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(3, 9, 25, 0.16);
}

.TwoFaModal-Title {
  margin: 0;
  margin-bottom: var(--space-l);
}

.TwoFaModal-ActivatedContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 64px 0;
}

.TwoFaModal-ActivatedText {
  margin-top: var(--space-m);
}

.TwoFaModal-Footer {
  margin-top: var(--space-xl);
  display: flex;
  justify-content: flex-end;
}

.TwoFaModal-Footer > .Button:not(:last-child) {
  margin-right: var(--space-xs);
}
